<template>
    <div>
        <input ref="input" v-if="editing" v-model="model" :placeholder="placeholder" @blur="change" class="input-edit w-100" :class="[classText, height]" @keypress.enter="change" :type="type" :rows="rows" />
        <div v-else :class="['input-edit', 'd-middle', 'rounded-lg', 'px-3', height]" style="max-width: 100%;" @click="setEdit">
            <p class="tres-puntos" :class="classText">{{ model ? model : placeholder }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: false,
        },
        classText: {
            type: String,
            default: 'text-dark-grey f-bold f-16',
        },
        type: {
            type: String,
            default: 'text',
        },
        height: {
            type: String,
            default: 'h-32px',
        },
        placeholder: {
            type: String,
            default: '',
        },
        rows: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            editing: false,
            model: this.modelValue,
        };
    },
    watch: {
        model: function (newVal) {
            this.$emit('update:modelValue', newVal);
        },
    },
    methods: {
        change() {
            this.editing = false;
            this.model = this.model.trim();
            this.$emit('input', this.model.trim());
            this.$emit('change', this.model.trim());
        },
        setEdit() {
            this.editing = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
    },
};
</script>
