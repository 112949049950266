<template>
    <modal ref="refModal" titulo="Configurar pagos" guardar tamano="modal-lg" adicional="Confirmar" @adicional="guardarConfiguracionPagos">
        <div class="px-3">
            <p class="f-20 f-500 mb-2">{{ conjunto.nombre }}</p>
            <div class="ml-3">
                <p class="f-12 pl-3">NIT del conjunto</p>
                <el-input v-model="conjunto.nit" placeholder="Número" size="small" class="wM-410px" />
            </div>
            <hr />
            <!-- conceptos -->
            <div>
                <p class="f-20 f-500 mb-2">Conceptos</p>
                <p class="f-15 mb-4">Define los conceptos que serán incluidos en el pago mensual de los residentes</p>
                <div class="ml-3">
                    <div class="d-flex gap-3">
                        <div class="">
                            <p class="f-12 pl-3">Nombre concepto</p>
                            <el-input v-model="modelConcepto.nombre" placeholder="Nombre" size="small" class="wf-200px" />
                        </div>
                        <div class="">
                            <p class="f-12 pl-3">Código concepto</p>
                            <el-input v-model="modelConcepto.codigo" placeholder="Número" size="small" class="wf-200px" />
                        </div>
                        <button class="d-flex mt-auto align-items-center btn-general btn btn-sm f-14 h-32px wf-100px" @click="agregarConcepto">
                            <i class="icon-add mr-2" /> Agregar
                        </button>
                    </div>
                </div>
                <div class="ml-3 mt-4">
                    <div class="d-middle gap-3 border-bottom w-fit mb-2">
                        <div class="wf-200px">
                            <p class="f-16 text-general f-500">Concepto</p>
                        </div>
                        <div class="wf-200px">
                            <p class="f-16 text-general f-500">Código</p>
                        </div>
                        <div class="wh-32 mt-auto" />
                    </div>
                    <template v-if="conceptosLista.length">
                        <div v-for="(item, index) in conceptosLista" :key="index" class="d-middle gap-3">
                            <div class="wf-200px">
                                <p class="text-86">{{ item.nombre }}</p>
                            </div>
                            <div class="wf-200px">
                                <p v-if="item.codigo" class="text-86">{{ item.codigo }}</p>
                                <p v-else class="text-86"> --- </p>
                            </div>
                            <div class="wh-32 mt-auto">
                                <el-tooltip content="Quitar" placement="right" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                    <i class="icon-close f-12 mt-1 cr-pointer" @click="deleteConcepto(item.id)" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <div v-else class="d-middle-center wM-464px text-gris">
                        No hay conceptos creados
                    </div>
                </div>
            </div>
            <hr />
            <!-- Descuento por pronto pago -->
            <div>
                <p class="f-20 f-500 mb-2">Descuento por pronto pago</p>
                <p class="f-15 mb-4">Define el día máximo en los que los residentes pueden obtener el descuento establecido</p>
                <div class="ml-3">
                    <div class="d-flex gap-3">
                        <div class="">
                            <p class="f-12 pl-3">Máximo día de plazo</p>
                            <el-input v-model="model.dia" placeholder="Día" size="small" class="wf-200px" :disabled="modelDescuento.dia != null" />
                        </div>
                        <div class="">
                            <p class="f-12 pl-3">Descuento</p>
                            <el-input v-model="model.descuento" placeholder="Porcentaje" size="small" class="wf-200px" :disabled="modelDescuento.descuento != null" />
                        </div>
                        <button class="d-flex mt-auto align-items-center btn-general btn btn-sm f-14 h-32px wf-100px" @click="agregarDescuento">
                            <i class="icon-add mr-2" /> Agregar
                        </button>
                    </div>
                </div>
                <div class="ml-3 mt-4">
                    <div class="d-middle gap-3 border-bottom w-fit mb-2">
                        <div class="wf-200px">
                            <p class="f-16 text-general f-500">Día</p>
                        </div>
                        <div class="wf-200px">
                            <p class="f-16 text-general f-500">Descuento</p>
                        </div>
                        <div class="wh-32 mt-auto" />
                    </div>
                    <template v-if="modelDescuento.dia != null && modelDescuento.descuento != null">
                        <div class="d-middle gap-3">
                            <div class="wf-200px">
                                <p class="text-86">{{ modelDescuento.dia }}</p>
                            </div>
                            <div class="wf-200px">
                                <p class="text-86">{{ modelDescuento.descuento }}%</p>
                            </div>
                            <div class="wh-32 mt-auto">
                                <el-tooltip content="Quitar" placement="right" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                    <i class="icon-close f-12 mt-1 cr-pointer" @click="quitarDescuento" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <div v-else class="d-middle-center wM-464px text-gris">
                        No hay descuento creado
                    </div>
                </div>
            </div>
            <hr />
            <!-- Fecha máxima de pago -->
            <div>
                <p class="f-20 f-500 mb-2">Fecha máxima de pago</p>
                <p class="f-15 mb-4">Define el día máximo en que los residentes pueden hacer el pago de administración</p>
                <div class="ml-3">
                    <p class="f-12 pl-3">Selecciona el día</p>
                    <el-input v-model="model.dia_pago" placeholder="Día" size="small" class="wf-200px" />
                    <!-- <el-date-picker v-model="pickerOptions" type="date" placeholder="Seleccionar la fecha" size="small" class="w-300px" /> -->
                </div>
            </div>
            <hr />
            <!-- Bancos -->
            <div>
                <p class="f-20 f-500 mb-2">Bancos</p>
                <p class="f-15 mb-4">Registra las cuentas bancarias donde los residentes pueden realizar el pago de la administración</p>
                <div class="ml-3">
                    <div class="row">
                        <div class="col-4">
                            <p class="f-12 pl-3">Banco</p>
                            <el-input v-model="model.banco" placeholder="Nombre banco" size="small" class="w-100" :disabled="modelBancos.banco != null" />
                        </div>
                        <div class="col-4">
                            <p class="f-12 pl-3">Tipo de cuenta</p>
                            <el-select v-model="model.tipo" clearable placeholder="Seleccionar tipo cuenta" size="small" class="w-100" :disabled="modelBancos.tipo != null">
                                <el-option
                                v-for="item in optionsCuenta"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="col-4">
                            <p class="f-12 pl-3">Número de cuenta</p>
                            <el-input v-model="model.numero_cuenta" placeholder="Número cuenta" size="small" class="w-100" :disabled="modelBancos.numero_cuenta != null" />
                        </div>
                    </div>
                    <div class="d-flex gap-3 mt-3">
                        <div class="flex-fill">
                            <p class="f-12 pl-3">Link de pago</p>
                            <el-input v-model="model.link" placeholder="Link de pago" size="small" class="w-100" :disabled="modelBancos.link != null" />
                        </div>
                        <button class="d-flex mt-auto align-items-center btn-general btn btn-sm f-14 h-32px wf-100px" @click="agregarBanco">
                            <i class="icon-add mr-2" /> Agregar
                        </button>
                    </div>
                </div>
                <div class="ml-3 mt-4">
                    <div class="d-middle justify-content-between gap-3 border-bottom mb-2">
                        <div class="w-130px">
                            <p class="f-16 text-general f-500">Banco</p>
                        </div>
                        <div class="w-130px">
                            <p class="f-16 text-general f-500">Tipo de cuenta</p>
                        </div>
                        <div class="w-130px">
                            <p class="f-16 text-general f-500"># cuenta</p>
                        </div>
                        <div class="w-130px">
                            <p class="f-16 text-general f-500">Link de pago</p>
                        </div>
                        <div class="w-45px h-32px mt-auto" />
                    </div>
                    <template v-if="modelBancos.banco != null">
                        <div class="d-middle justify-content-between gap-3">
                            <div class="wf-130px">
                                <p class="text-86">{{ modelBancos.banco }}</p>
                            </div>
                            <div class="wf-130px">
                                <p class="text-86">{{ modelBancos.tipo }}</p>
                            </div>
                            <div class="wf-130px">
                                <p class="text-86">{{ modelBancos.numero_cuenta }}</p>
                            </div>
                            <div class="wf-130px">
                                <a class="text-86 cr-pointer tres-puntos" :href="modelBancos.link" target="_blank">
                                    <p class="tres-puntos"> {{ modelBancos.link }} </p>
                                </a>
                            </div>
                            <div class="w-45px h-32px d-middle mt-auto">
                                <el-tooltip v-if="modelBancos.link != null" content="Copiar link" placement="bottom" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                    <i class="icon-popup f-18 mt-1 cr-pointer" />
                                </el-tooltip>
                                <el-tooltip content="Quitar" placement="bottom" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                    <i class="icon-close f-12 mt-1 cr-pointer" @click="eliminarBanco" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <div v-else class="d-middle-center wM-464px text-gris">
                        No hay cuentas de bancos registradas
                    </div>
                </div>
            </div>
            <hr />
            <!-- Comentario -->
            <div class="mt-3">
                <div class="ml-3">
                    <p class="f-12 pl-3">Comentario en la factura</p>
                    <el-input v-model="model.comentario" placeholder="Escribe algún comentario que deseas que aparezca en la factura" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" show-word-limit maxlength="500" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Administracion from '~/services/pago_administracion'

export default {
    props: {
        action: {
            type: Function,
            default: () => {}
        }
    },
    data(){
        return {
            pickerOptions: '',
            model: {
                dia: null,
                descuento: null,
                dia_pago: null,
                banco: null,
                tipo: null,
                numero_cuenta: null,
                link: null,
                comentario: null,
            },
            conjunto:{
                nombre: '',
                nit: '',
            },
            modelDescuento:{
                dia: null,
                descuento: null,
            },
            modelBancos:{
                banco: null,
                tipo: null,
                numero_cuenta: null,
                link: null,
            },
            optionsCuenta: [
                {
                    value: 'Cuenta ahorros',
                    label: 'Cuenta ahorros'
                },
                {
                    value: 'Cuenta corriente',
                    label: 'Cuenta corriente'
                },
            ],
            modelConcepto:{
                nombre: null,
                codigo: null,
            },
            conceptosLista:[],
        }
    },
    methods: {
        async toggle(){
            await this.getConfiguracionPagos();
            await this.getConceptosPagos();
            this.$refs.refModal.toggle()
        },
        actualizar(){
            this.$refs.refModal.toggle()
        },
        async getConfiguracionPagos(){
            try {
                const { data } = await Administracion.configuracionPagos()
                if (data.success){
                    this.conjunto.nombre = data.data.conjunto.nombre;
                    this.conjunto.nit = data.data.conjunto.nit;

                    this.modelDescuento.dia = data.data.configuracion.dia;
                    this.modelDescuento.descuento = data.data.configuracion.descuento;

                    this.model.dia_pago = data.data.configuracion.dia_pago;

                    this.modelBancos.banco = data.data.configuracion.banco;
                    this.modelBancos.numero_cuenta = data.data.configuracion.numero_cuenta;
                    this.modelBancos.tipo = data.data.configuracion.tipo_cuenta;
                    this.modelBancos.link = data.data.configuracion.link_pago;

                    this.model.comentario = data.data.configuracion.comentario;
                }
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async getConceptosPagos(){
            try {
                const { data } = await Administracion.getConceptos();

                if (data.success){
                    this.conceptosLista = data.data;
                }
            } catch (error){
                return this.errorCatch(error)
            }
        },
        quitarDescuento(){
            this.modelDescuento.dia = null;
            this.modelDescuento.descuento = null;
        },
        agregarDescuento(){
            if (this.modelDescuento.dia != null && this.modelDescuento.descuento != null){
                return this.notificacion('','Ya se encuentra agregado un descuento','error')
            }else{
                if (this.model.dia > 30)return this.notificacion('','El día no puede ser mayor a 30','error');
                if (this.model.descuento > 100)return this.notificacion('','El descuento no puede ser mayor a 100','error');

                this.modelDescuento.dia = this.model.dia;
                this.modelDescuento.descuento = this.model.descuento;

                this.model.dia = null;
                this.model.descuento = null;
            }
        },
        agregarBanco(){
            if (this.modelBancos.banco != null && this.modelBancos.tipo != null && this.modelBancos.numero_cuenta != null && this.modelBancos.link != null){
                return this.notificacion('','Ya se encuentra agregado un banco','error')
            }else {
                if (this.model.banco){
                    this.modelBancos.banco = this.model.banco;
                    this.model.banco = null;
                }
                if (this.model.tipo){
                    this.modelBancos.tipo = this.model.tipo;
                    this.model.tipo = null;
                }
                if (this.model.numero_cuenta){
                    this.modelBancos.numero_cuenta = this.model.numero_cuenta;
                    this.model.numero_cuenta = null;
                }
                if (this.model.link){
                    this.modelBancos.link = this.model.link;
                    this.model.link = null;
                }
            }
        },
        eliminarBanco(){
            this.modelBancos.banco = null;
            this.modelBancos.tipo = null;
            this.modelBancos.numero_cuenta = null;
            this.modelBancos.link = null;
        },
        async agregarConcepto(){
            try {
                const payload = {
                    'nombre': this.modelConcepto.nombre,
                    'codigo': this.modelConcepto.codigo,
                }

                const { data } = await Administracion.postConceptos(payload);

                if (data.success){
                    const concepto = {
                        'id': data.data.id,
                        'nombre': data.data.nombre,
                        'codigo': data.data.codigo ? data.data.codigo : null
                    }

                    this.conceptosLista.push(concepto);

                    this.modelConcepto.nombre = null;
                    this.modelConcepto.codigo = null;
                }
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async deleteConcepto(idConcepto){
            try {
                const { data } = await Administracion.deleteConcepto(idConcepto);

                if (data.success){
                    this.conceptosLista = this.conceptosLista.filter(concepto => concepto.id != idConcepto);
                }
            } catch (error){
                return this.errorCatch(error);
            }
        },
        async guardarConfiguracionPagos(){
            try {
                const payload = {
                    'nit': this.conjunto.nit,
                    'maximo_dia': this.modelDescuento.dia,
                    'descuento': this.modelDescuento.descuento,
                    'maximo_dia_pago': this.model.dia_pago,
                    'banco': this.modelBancos.banco,
                    'tipo_cuenta': this.modelBancos.tipo,
                    'numero_cuenta': this.modelBancos.numero_cuenta,
                    'link_pago': this.modelBancos.link,
                    'comentario': this.model.comentario,
                };

                const { data } = await Administracion.postConfiguracionPagos(payload);

                if (data.success){
                    this.notificacion('',data.mensaje,'success');
                    this.$refs.refModal.toggle();
                    this.$emit('actualizar')
                }
            } catch (error){
                return this.errorCatch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>