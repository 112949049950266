import axios from 'axios'

const API = '/administracion'

const Administracion = {
    configuracionPagos(){
        return axios.get(`configuracion-pagos`)
    },
    getConceptos(){
        return axios.get(`configuracion-pagos/conceptos`)
    },
    postConceptos(payload){
        return axios.post(`configuracion-pagos/conceptos`, payload)
    },
    deleteConcepto(idConcepto){
        return axios.delete(`configuracion-pagos/conceptos/${idConcepto}`)
    },
    postConfiguracionPagos(payload){
        return axios.post(`configuracion-pagos`, payload)
    },
    getReciboPagosAnio(){
        return axios.get(`${API}/anio`)
    },
    listarViviendaConceptos(params){
        return axios.get(`${API}/recibo-pagos`, {params})
    },
    importarExcelEstructura(form){
        return axios.post(`${API}/importar/vivienda-conceptos`, form)
    },
    putViviendaConcepto(payload){
        return axios.put(`${API}/editar/vivienda-conceptos`, payload)
    },
    listarControlPagos(){
        return axios.get(`${API}/actualizar/control-pagos`)
    },
    updateControlPagos(payload){
        return axios.put(`${API}/actualizar/control-pagos`, payload)
    },
    listarRegistroPagos(params){
        return axios.get(`${API}/registro/pagos`, {params})
    },
    listarViviendaRegistroPagos(params){
        return axios.get(`${API}/registro/pagos/vivienda`, {params})
    },
    updateControlPagoVivienda(payload){
        return axios.put(`viviendas/control/pago`, payload)
    },
    listarDetalleVivienda(params){
        return axios.get(`${API}/detalle-vivienda`, {params})
    },
    listarRegistroPagoVivienda(params){
        return axios.get(`${API}/registro/pagos/vivienda/detalle`, {params})
    },
    updateEstadoPago(payload){
        return axios.put(`${API}/pagos/vivienda/actualizar-estado`, payload)
    },
    enviarReciboPagos(payload){
        return axios.post(`${API}/enviar-recibo`, payload)
    },
    getUserContador(){
        return axios.get(`${API}/listar-contador`)
    },
    postUserContador(payload){
        return axios.post(`${API}/contador`, payload)
    },
    deletetUserContador(idContador){
        return axios.delete(`${API}/contador/${idContador}`)
    },
    putUserContador(payload){
        return axios.put(`${API}/contador`, payload)
    },
    generarPdfPagos(idVivienda, params){
        return axios.get(`${API}/${idVivienda}/generar-pdf`, {
            responseType: 'blob',
            params: params,
        })
    },
}

export default Administracion
