<template>
    <modal-lateral ref="modalFiltroVisitante" titulo="Filtrar" width="30%">
        <div class="d-flex flex-column justify-content-between h-100 px-4">
            <div class="d-flex flex-column gap-2">
                <div v-if="mostrarFiltroVivienda">
                    <p class="f-medium f-15 text-black mb-3">Filtrar por vivienda</p>
                    <div class="mb-3">
                        <label for="agrupacion" class="d-block pl-2 text-general f-12">Agrupación</label>
                        <el-select id="agrupacion" v-model="agrupacion_id" class="br-5 d-block" clearable @change="alCambiarAgrupacion">
                            <el-option
                            v-for="item, index in agrupaciones"
                            :key="index.id"
                            :value="item.id"
                            :label="item.nombre"
                            />
                        </el-select>
                    </div>
                    <div class="mb-3">
                        <label for="vivienda" class="d-block pl-2 text-general f-12">Vivienda</label>
                        <el-select id="vivienda" v-model="vivienda_id" class="br-5 d-block" clearable>
                            <el-option
                            v-for="item, index in viviendas"
                            :key="index.id"
                            :value="item.id"
                            :label="item.nombre"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-if="mostrarFiltroFecha">
                    <p class="f-medium f-15 text-black mb-3">Filtrar por fecha</p>
                    <div class="mb-3">
                        <label for="fechaInicio" class="d-block pl-2 text-general f-12">Fecha inicio</label>
                        <el-date-picker
                        id="fechaInicio"
                        v-model="fecha_inicio"
                        type="date"
                        format="d MMM yyyy"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="fechaFin" class="d-block pl-2 text-general f-12">Fecha fin</label>
                        <el-date-picker
                        id="fechaFin"
                        v-model="fecha_fin"
                        type="date"
                        format="d MMM yyyy"
                        />
                    </div>
                </div>
                <div v-if="mostrarFiltroTipo">
                    <p class="f-medium f-15 text-black mb-3">Tipo de visita</p>
                    <div class="mb-3">
                        <el-checkbox-group v-model="tipo" class="el-checkbox-negro">
                            <el-checkbox :label="1">Caminando</el-checkbox>
                            <el-checkbox :label="2">Carro</el-checkbox>
                            <el-checkbox :label="3">Moto</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <el-divider class="mb-2" />
                <div class="d-flex justify-content-end">
                    <button
                    type="button"
                    class="btn mx-2 btn-cerrar f-300 f-14 br-4 border f-300 bg-light"
                    @click="limpiarFiltro"
                    >
                        Borrar filtro
                    </button>
                    <button
                    type="button"
                    class="btn mx-2 f-14 btn-aceptar f-300 text-white br-4 f-300"
                    @click="$emit('filtrar', filtro_tooltip)"
                    >
                        Filtrar
                    </button>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        agrupaciones: {
            type: Array,
            default: () => [],
        },
        viviendas: {
            type: Array,
            default: () => [],
        },
        mostrarFiltroVivienda: {
            type: Boolean,
            default: false
        },
        mostrarFiltroFecha: {
            type: Boolean,
            default: false
        },
        mostrarFiltroTipo: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            agrupacion_id: null,
            vivienda_id: null,
            fecha_inicio: '',
            fecha_fin: '',
            torres: [],
            tipo: []
        }
    },
    computed: {
        fecha_inicio_formated(){
            if(!this.fecha_inicio) return null
            return moment(this.fecha_inicio).format("YYYY-MM-DD")
        },
        fecha_fin_formated(){
            if(!this.fecha_fin) return null
            return moment(this.fecha_fin).format("YYYY-MM-DD")
        },
        filtro_tooltip(){
            let arr = []
            const agrupacion = this.agrupaciones.find(el => el.id == this.agrupacion_id)
            if(agrupacion){
                arr.push({
                    id: 1,
                    titulo: 'Agrupación',
                    nombre: agrupacion?.nombre ?? '',
                })
            }
            const vivienda = this.viviendas.find(el => el.id == this.vivienda_id)
            if(vivienda){
                arr.push({
                    id: 2,
                    titulo: 'Vivienda',
                    nombre: vivienda?.nombre ?? ''
                })
            }
            if(this.fecha_inicio){
                arr.push({
                    id: 3,
                    titulo: "Fecha inicio",
                    nombre: moment(this.fecha_inicio).format('DD MMM YYYY'),
                })
            }
            if(this.fecha_fin){
                arr.push({
                    id: 4,
                    titulo: "Fecha fin",
                    nombre: moment(this.fecha_fin).format('DD MMM YYYY'),
                })
            }

            const datosFiltro = {
                tipo: this.tipo,
                datos: arr
            }
            return datosFiltro;
        }
    },
    methods: {
        toggle(){
            this.$refs.modalFiltroVisitante.toggle();
        },
        limpiarFiltro(){
            this.agrupacion_id = null
            this.vivienda_id = null
            this.fecha_inicio = ''
            this.fecha_fin = ''
            this.tipo = []
            this.$emit('limpiar')
        },
        alCambiarAgrupacion(){
            this.$emit('agrupacionSeleccionada', this.agrupacion_id)
            this.vivienda_id = null
        },
    }
}
</script>

<style lang="scss" scoped>

</style>